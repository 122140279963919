<template>
  <div class="swiper-container">
    
    <swiper :modules="modules" :pagination="pagination" :effect="'fade'" class="swiper flex-col" :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }" @swiper="setSwiperRef" @autoplayTimeLeft="onAutoplayTimeLeft" :style="{
    '--swiper-progress': swiperProgress
    }">
    <!-- <Swiper
      ref="swiperRef"
      :modules="modules"
      :loop="true"
      :autoplay="{ delay: 4000, disableOnInteraction: false }"
      :slides-per-view="1"
      :pagination="{
        clickable: true,
        type: 'custom',
        renderCustom: renderCustomPagination,
      }"
      @slideChange="handleSlideChange"
    > -->
    <!-- :style="{
          background: `url(${useUserStore().language === 'zh-CN' ? item.img : item.img_en }) 50% 50% / cover`,
        }" -->
      <SwiperSlide
        v-slot="{ isActive }"
        v-for="item in props.data"
        :key="item.id"
        
      >
        <Image @click="jumpFn(item)" fit="cover" style="width: 100%; height: 100%; cursor: pointer;" :src="useUserStore().language === 'zh-CN' ? item.img : item.img_en" :alt="useUserStore().language === 'zh-CN' ? item.img : item.img_en" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
export default {
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>

<script lang="ts" setup>
import { defineProps, onMounted, onBeforeUnmount, ref, nextTick } from "vue";
import { useRoute } from "vue-router";

import { useUserStore } from "@/stores/user";

import { Autoplay, Pagination, EffectFade } from "swiper/modules";

const modules = [Pagination, Autoplay, EffectFade];
const pagination = {
  clickable: true,
  renderBullet: function (index: any, className: string) {
    return '<span class="' + className + '"></span>';
  },
}

const route = useRoute();

const props = defineProps({
  height: {
    type: Number || String,
    default: 800,
  },
  data: {
    type: Array,
    default: [],
  },
});

console.log(props.data)

const jumpFn = (item) => {
  console.log(item)
  window.location.href = item.jump_url;
};

const swiperRef = ref(null)
const swiperProgress = ref('0')
const setSwiperRef = async (swiper) => {
  if(props.data) {
    swiperRef.value = swiper;
    await nextTick()
  }
}
const onAutoplayTimeLeft = (s, time, progress) => {
  swiperProgress.value = Math.round((1 - progress) * 100) + '%'
}

onMounted(() => { });

onBeforeUnmount(() => {

});
</script>

<style lang="less" scoped>
.swiper-container,
.swiper {
  height: 100%;
  z-index: 2;
  width: 100%;
  position: relative;
}

.swiper-slide{
  width: 100%;

  img{
    cursor: pointer;
  }
}

.swiper ::v-deep .swiper-pagination{
  width: @--effect-width;
  align-self: center;
  left: unset;
  text-align: start;
  position: absolute;
  bottom: 3%;
  
}
.swiper ::v-deep .swiper-pagination .swiper-pagination-bullet{
  width: 60px;
  height: 4px;
  background: rgba(255,255,255,0.7);
  border-radius: 4px;
  opacity: 1;
}
.swiper ::v-deep .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  position: relative;
}
.swiper ::v-deep .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--swiper-progress);
  background: #0051F2;
  border-radius: 4px;
}
</style>
