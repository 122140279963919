<template>
  <div class="navbar-container">
  <div class="navbar-container ifm-hidden flex-col items-center" @mouseenter="() => (isHoverHead = true)"
      @mouseleave="() => (isHoverHead = false)">
      <div class="content-container flex-row items-stretch justify-center" >
        <div class="effect-content-container flex-row items-stretch">
          <img class="logo self-center" alt="logo" :src="logo" @click="goHome" :style="{ width: $pxToRem(logoWidth), height: $pxToRem(logoHeight) }" />
          <div class="flex-1"></div>
          <Space :columnGap="$pxToRem(20)" class="navs flex-row">
            <component :is="'div'" class="nav flex-col flex-center" ref="nav"
              :to="item.route ?? null" :class="{ active: isActiveRoute(item) }" v-for="(item, index) in navs" :key="index"
              @mouseenter="() => { debounceSetIsHoverDropdown(true), hoverIndex = index, titleHover(item) }"
              @click="goPage(item)"
              @mouseleave="debounceSetIsHoverDropdown(false)">
              {{ $zhEn(item.title,item.title_en) }}
            </component>
          </Space>
          <div class="btns flex-row items-center">
            <span class="right_item flex-row items-center" @click="showMask = true">
              <img src="@/assets/images/search_icon.png" style="cursor: pointer;" :alt="$t('search')" />
              <span >{{ $t("search") }}</span>
            </span>
            <span class="right_item flex-row items-center" ref="spanBox">
              <img src="@/assets/images/language_icon.png" :alt="useUserStore().language" />
              <span class="mr-12" :class="useUserStore().language === 'zh-CN' ? 'def_color' : ''" @click="checkLang(1,'zh-CN')">中文</span>
              <span :class="useUserStore().language === 'en-US' ? 'def_color' : ''" @click="checkLang(2, 'en-US')">EN</span>
            </span>
          </div>
        </div>
      </div>

      <transition name="fade">
        <NavbarDropdown v-if="navbarDropdownAttrs" v-bind="navbarDropdownAttrs" class="navbar-dropdown"
          @mouseenter="debounceSetIsHoverDropdown(true)" @mouseleave="debounceSetIsHoverDropdown(false)" />
      </transition>
    </div>
    <div class="mask_top flex-center" v-show="showMask">
      <div class="search_box flex-center">
        <input class="search_ipt_css flex-1" type="text" v-model="searchVal" />
        <img
          class="sea_icon"
          src="@/assets/images/search_small_icon@2x.png"
          :alt="$t('search')"
          @click="jumpSearch"
        />
      </div>
      <img
        class="close_icon"
        src="@/assets/images/close_icon@2x.png"
        @click="showMask = false"
        alt="close"
      />
    </div>
  </div>
  
</template>

<script setup>
// import { useScrollerStore } from '@/common/stores/scroller'
import NavbarDropdown from "@/common/pageComponents/NavbarDropdown/01.vue";
import { pxToRem } from "@/utils/remUtil";
import { getRem } from "@/utils/amfeFlexible";
import { useUserStore } from "@/stores/user";
import { useI18n } from "vue-i18n";
import debounce from 'lodash/debounce'
import { useRouter,useRoute } from "vue-router";
import { defineProps, ref, onMounted, computed } from "vue";
const router = useRouter();
const route = useRoute();

const i18n = useI18n();
// const $getRem = getRem()
const props = defineProps({
  logo: {
    type: String,
    default: ''
  },
  logoWidth: {
    type: String,
    default: Number(182)
  },
  logoHeight: {
    type: String,
    default: Number(50)
  },
  navs: {
    type: Array,
    default: function () {
      return [
        {
          name: "首页",
          //如果需要跳转则传这个
          route: {
            path: '/'
          },
          //如果需要判断是否active 传这个 不传则使用参数route与当前route判断
          activePrefixes: ['/product']
        },
        {
          name: "产品",
          route: {
            path: '/product'
          }
        },
        {
          name: "解决方案",
          route: {
            path: '/solution'
          }
        },
        {
          name: "核心技术",
          route: {
            path: '/technology'
          }
        },
        {
          name: "隐私协议",
          route: {
            path: '/privacy'
          }
        },
        {
          name: "成功案例",
          route: {
            path: '/case'
          }
        },
        {
          name: "关于数盟",
          route: {
            path: '/about'
          }
        }
      ]
    }
  },
  btns: {
    type: Array,
    default: function () {
      return [
      ]
    }
  }
})

let spanBox = ref("spanBox");
let searchVal = ref("");
let showMask = ref(false);
let isHoverDropdown = ref(false)
let isHoverHead = ref(false)
let hoverIndex = ref(null)
let languageIndex = ref(1)

let debounceSetIsHoverDropdown = ref(null)
let nav = ref("nav");
let navbarDropdownAttrs = computed(() =>{
  if (isHoverDropdown.value && hoverIndex.value !== null) {
  const drapdown = props.navs[hoverIndex.value].drapdown
    if (drapdown) {
      const el = nav.value[hoverIndex.value]?.$el ?? nav.value[hoverIndex.value]
      const rect = el.getBoundingClientRect()
      console.log(rect)
      if (drapdown.widthPx) {
        if (rect.left + rect.width / 2 + (parseFloat(pxToRem(drapdown.widthPx)) * getRem() / 2) > window.innerWidth) {
          drapdown.style = {
            right: '0',
          }
        } else {
          drapdown.style = {
            left: (rect.left + rect.width / 2) + 'px',
            transform: 'translateX(-50%)'
          }

        }
      }
      return drapdown
    }
  }
  console.log(navbarDropdownAttrs.value)
  return null

})

const jumpSearch = () => {
  if (searchVal.value) {
    showMask.value = false;
    router.push({ path: "/search", query: { keyword: searchVal.value } });
  }
};

const goPage = (item) => {
  if (item.id === 1) {
    if(route.path == '/keymos') {
      location.reload();
    } else {
      router.push("/keymos");
    }
  } else if (item.id === 2) {
    if(route.path == '/products') {
      location.reload();
    }
    if(item.data.length != 0) {
      router.push(`/products?id=${item.data[0].id}`);
    }
  } else if (item.id === 3) {
    if(route.path == '/solution') {
      location.reload();
    } else {
      router.push("/solution");
    }
  } else if (item.id === 4) {
    if(route.path == '/aboutus') {
      location.reload();
    } else {
      router.push("/aboutus");
    }
  } else if (item.id === 5) {
    if(route.path == '/contactus') {
      location.reload();
    } else {
      router.push("/contactus");
    }
  } else if (item.id === 6) {
    console.log(route)
    if(route.path == '/serviceSupport') {
      location.reload();
    } else {
      router.push("/serviceSupport");
    }
    
  } else if (item.id === 7) {
    if(route.path == '/newslist') {
      location.reload();
    } else {
      router.push(`/newslist?id=${item.data[0].id}`);
    } 
  }
};

const setIsHoverDropdown = (bool) =>{
  isHoverDropdown.value = bool
}

const titleHover = (item) => {
  console.log(item)
}

onMounted(() => {
  debounceSetIsHoverDropdown.value = debounce(setIsHoverDropdown, 50)
});

const checkLang = (idx,local) => {
  languageIndex.value = idx
  // spanBox.value.children[1].style.color = "#ffffff";
  // spanBox.value.children[2].style.color = "#ffffff";
  // spanBox.value.children[idx].style.color = "#0083ff";
  changelang(local);
}

function changelang(local) {
  console.log(local)
  console.log(i18n.locale.value)
  i18n.locale.value = local;
  useUserStore().setLanguage(local);

}

const isActiveRoute = (item) =>{
  if (item.activePrefixes && item.activePrefixes.length > 0) {
    //如果有特定的active配置
    for (const activePrefix of item.activePrefixes) {
      if (router.fullPath.startsWith(activePrefix)) {
        return true
      }
    }
    return false
  } else if (item.route) {
    //如果没有特定的active配置 使用route判断
    for (const key in item.route) {
      if (Object.hasOwnProperty.call(item.route, key)) {
        if (item.route[key] != router[key]) {
          return false
        }
      }
    }
    return true
  } else {
    return false
  }
}

const goHome = () => {
  router.push('/')
}




</script>

<style scoped lang="less">
.navbar-container {
  width: 100%;
  z-index: 101;
  position: sticky;
  top: 0;
  background: #121619;
  .content-container {
    z-index: 101;
    font-size: 18px;
    color: #333333;
    line-height: 26px;
    width: 100%;
    height: @--nav-height-pc;
    // transition: background-color 0.15s, color 0.15s, box-shadow 0.15s;

    .effect-content-container {
      width: @--effect-width;

      .logo {
        height: 50px;
        width: 182px;
        cursor: pointer;
      }
      

      .navs {
        
        .nav {
          cursor: pointer;
          position: relative;
          color: #FFFFFF;
          font-weight: normal;
          text-align: center;
          &.active,
          &:hover {
            color: #0083ff;
          }

          &.active::after {
            content: '';
            position: absolute;
            background: @--color-primary;
            height: 4px;
            border-radius: 2px;
            left: 0;
            right: 0;
            bottom: 18px;
          }
        }
      }

      ::v-deep .navs{
        gap: 6px;
        .nav{
          max-width: 130px;
        }
      }

      .btns {
        margin-left: 30px;
        .right_item {
          padding: 0px 10px;
          color: #ffffff;
          border-left: 1px rgba(255, 255, 255, 0.5) solid;

          // &:first-of-type {
          //   border-left: none; // 去掉第一个类型为 .right_item 的元素的边框样式
          // }

          img {
            width: 20px;
            height: 20px;
            vertical-align: top;
            margin-right: 10px;
          }

          span {
            cursor: pointer;
            font-size: 14px;
          }

          span:hover {
            color: #0083ff;
          }
        }

        .right_item:last-child {
          padding-right: 0;
          /* 调整为你需要的内边距值 */
        }
      }
    }

    &.active {
      color: #262626;

      .nav {
        color: #262626;

        .line {
          &.active {
            background: #27ae60;
          }
        }
      }
    }
  }

  .navbar-dropdown {
    position: absolute;
    top: @--nav-height-pc;
    // padding-top: @--nav-height-pc-margin-bottom;
  }
}
.def_color{
  color: #0083ff;
}
.mr-12 {
  margin-right: 12px;
}
.mask_top {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    z-index: 102;

    .search_box {
      width: 600px;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #979797;
      background: rgba(255, 255, 255, 1);
      padding: 0px 20px;

      @media (max-width: 768px) {
        width: 300px;
      }

      .search_ipt_css {
        height: 30px;
        font-size: 22px;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
      }

      .sea_icon {
        width: 20px;
        height: 20px;
        margin-left: 12px;
        cursor: pointer;
      }
    }

    .close_icon {
      width: 20px;
      height: 20px;
      margin-left: 60px;
      cursor: pointer;
    }
  }
</style>
